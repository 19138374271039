// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2022_05-module--maineDigest--gN76-";
export var heroImage = "maine_digest_2022_05-module--heroImage--Io+-X";
export var columnWrapper = "maine_digest_2022_05-module--columnWrapper--FLwy8";
export var headingLogo = "maine_digest_2022_05-module--headingLogo--+wZYu";
export var headingWrapper = "maine_digest_2022_05-module--headingWrapper--nhIvr";
export var heading = "maine_digest_2022_05-module--heading--NDgnx";
export var instance = "maine_digest_2022_05-module--instance--6vndb";
export var subheading = "maine_digest_2022_05-module--subheading--JBTS1";
export var textWrapper = "maine_digest_2022_05-module--textWrapper--R9OvX";
export var latestnewsarticleheadline = "maine_digest_2022_05-module--latestnewsarticleheadline--H+-g7";
export var dateline = "maine_digest_2022_05-module--dateline--S8NDs";
export var whatsNew = "maine_digest_2022_05-module--whatsNew--6f8Ue";
export var colWrapper = "maine_digest_2022_05-module--colWrapper--xjPyE";
export var leftCol = "maine_digest_2022_05-module--leftCol--i0QLJ";
export var rightCol = "maine_digest_2022_05-module--rightCol--Q0r7u";
export var photoCaption = "maine_digest_2022_05-module--photoCaption--Vn1TS";
export var afterCaption = "maine_digest_2022_05-module--afterCaption--pv290";